import React from "react"
import { Col } from "react-bootstrap"
import { MainLayout, Section } from "../components/containers"
import SEO from "../components/SEO"
import { MainButton, BgImage } from "../components/ui"
import { H1, Container, H2 } from "../components/elements"
import Styled from "styled-components"

const Headband = Styled.section`
  
  h1 {
    color: white;
  }
  p{
    color: white;
  }
  .contactbtn{
    margin-top: 50px
  }
`

const LegalPage = ({ data }) => {
  const title =
    data.title.childContentfulSettingValueRichTextNode.childContentfulRichText
      .html
  const subtitle =
    data.title.childContentfulSettingContentRichTextNode.childContentfulRichText
      .html
  const legal =
    data.legal.childContentfulSettingContentRichTextNode.childContentfulRichText
      .html
  return (
    <MainLayout>
      <SEO title="Mention légal" />
      <Headband>
        <BgImage>
          <Section>
            <Container fluid>
              <Col className="p-0" lg={8}>
                <H1 dangerouslySetInnerHTML={{ __html: title }} />
                <H2 dangerouslySetInnerHTML={{ __html: subtitle }} />
              </Col>
              <div className="mb-5 contactbtn mb-lg-0">
                <MainButton to="/#contact">Contact</MainButton>
              </div>
            </Container>
          </Section>
        </BgImage>
      </Headband>
      <Container>
        <Section>
          <div dangerouslySetInnerHTML={{ __html: legal }} />
        </Section>
      </Container>
    </MainLayout>
  )
}

export default LegalPage

export const pageQuery = graphql`
  query {
    legal: contentfulSetting(name: { eq: "legal" }) {
      childContentfulSettingContentRichTextNode {
        childContentfulRichText {
          html
        }
      }
    }
    title: contentfulSetting(name: { eq: "title" }) {
      name
      childContentfulSettingValueRichTextNode {
        childContentfulRichText {
          html
        }
      }
      childContentfulSettingContentRichTextNode {
        childContentfulRichText {
          html
        }
      }
    }
  }
`
